import {
	Button,
	Chip,
	Link,
	List,
	ListItem,
	NavLeft,
	NavTitle,
	Navbar,
	Preloader,
	Searchbar,
	Subnavbar,
	Tab,
	f7,
	useStore,
} from "framework7-react";
import React, { useState, useEffect, useRef } from "react";
import user from "../../../../assets/images/placeholders/user.png";
import MaterialIcon from "../../../../components/misc/materialIcon";
import { useDispatch, useSelector } from "react-redux";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../../../js/db";
import { editGroupService } from "../../../../redux/features/chatSlice/editGroup";
import { useTranslation } from "react-i18next";
import LazyLoaderWraper from "../../../../components/misc/lazyloaderWraper";
import { useUpdateEffect } from "react-use";

const AddMoreParticipants = ({ chat_id, switchMode }) => {
	const activeConversation = useStore("activeConversation");
	if (activeConversation) {
		chat_id =
			activeConversation !== "null"
				? activeConversation !== "posh_ai"
					? parseInt(activeConversation)
					: activeConversation
				: activeConversation;
	}
	const dispatch = useDispatch();
	const { t, i18n } = useTranslation();
	const searchbar = useRef(null);
	const contactsList = useRef(null);
	const [selectedItems, setSelectedItems] = useState([]);
	const [groupName, setGroupName] = useState(false);

	useUpdateEffect(() => {
		searchbar.current && f7.searchbar.destroy(searchbar.current.el);
		setSelectedItems([]);
		setGroupName(false);
	}, [chat_id]);

	const members = useLiveQuery(async () => {
		if (!chat_id || chat_id === "null" || chat_id === NaN) return [];
		return await db.members.where({ chat_id: chat_id !== "posh_ai" ? parseInt(chat_id) : chat_id }).toArray()
	}, [chat_id], []);
	const contacts = useLiveQuery(() => db.users.orderBy("name").toArray());

	const { getGroupDetailResponse, isLoading } = useSelector(
		(state) => state.groupDetailSlice
	);

	const filteredContacts = contacts?.filter(
		(contact) => !members.some((member) => member.id === contact.id)
	);

	let memberlist = null;
	if (filteredContacts?.length === 0) {
		memberlist = (
			<p className="flex flex-col justify-center items-center text-[--f7-heading-color] font-bold">
				No Participants to add
			</p>
		);
	}
	// filteredContacts now contains the elements that are in contacts but not in members
	const handleItemClick = (item) => {
		const itemId = item.id;
		const isSelected = selectedItems.map((selectedItem) => selectedItem.id);

		if (isSelected.includes(itemId)) {
			// If item is already selected, remove it
			const newSelectedItems = selectedItems.filter(
				(selectedItem) => selectedItem.id !== itemId
			);
			setSelectedItems(newSelectedItems);
		} else {
			// If item is not selected, add it
			const newSelectedItems = [...selectedItems, item];
			setSelectedItems(newSelectedItems);
		}
	};

	const groupUsers = selectedItems?.map((user) => user?.id);

	useEffect(() => {
		setGroupName(getGroupDetailResponse?.data?.[0].group_name);
	}, [getGroupDetailResponse]);

	// const [vlData, setVlData] = useState({
	// 	items: [],
	// });
	// const renderExternal = (vl, newData) => {
	// 	setVlData({ ...newData });
	// };

	// const searchAll = (query, searchItems) => {
	// 	const found = [];
	// 	for (let i = 0; i < searchItems.length; i += 1) {
	// 		if (
	// 			searchItems[i]?.name ||
	// 			searchItems[i]?.NAME ||
	// 			`User-${searchItems[i]?.id}`
	// 				.toLowerCase()
	// 				.indexOf(query.toLowerCase()) >= 0 ||
	// 			query.trim() === ""
	// 		)
	// 			found.push(i);
	// 	}
	// 	return found;
	// };

	useEffect(() => {
		if (contacts?.length > 0) {
			// if (contactsList.current) {
			// 	f7.virtualList.create({
			// 		el: contactsList.current.el,
			// 		items: contacts,
			// 		createUl: false,
			// 		renderExternal,
			// 		searchAll,
			// 		height: "auto",
			// 	});
			// }
			if (searchbar.current) {
				f7.searchbar.create({
					el: searchbar.current.el,
					backdrop: false,
					inner: false,
					disableButton: false,
					customSearch: false,
				});
			}
		}
	}, [contacts, searchbar]);

	const AddParticipants = () => {
		dispatch(
			editGroupService({
				chat_id: chat_id, // Add the chat_id if needed
				group_name: groupName,
				add_group_members: groupUsers,
			})
		);
		switchMode();
	};
	return (
		<Tab>
			<Navbar>
				<NavLeft>
					<Link onClick={switchMode}>
						<MaterialIcon
							size={48}
							icon="arrow_back"
							fill
							color="--f7-chat-heading-color"
						/>
					</Link>
				</NavLeft>
				<NavTitle className="text-[--f7-heading-color] font-bold">
					{t("Add Group Participants")}
				</NavTitle>
			</Navbar>
			<Subnavbar>
				{!isLoading && contacts?.length > 0 && (
					<Searchbar
						ref={searchbar}
						inline
						backdrop={false}
						disableButton={false}
						inner={false}
						placeholder={t("search")}
						className="w-full"
						searchContainer=".contact-list2"
						searchIn=".item-title"
					/>
				)}
			</Subnavbar>

			<div className="page-content pr-0 h-[100vh]">
				<div className="flex px-5 gap-2 flex-wrap ">
					{selectedItems?.map((item) => (
						<Chip
							key={item.id}
							className="bg-[--f7-navbar-bg-color] pr-5 font-[400]  rounded-[100px] text-[12px] text-[--f7-heading-color] md:text-[14px]"
							text={item.name || item.NAME || `User-${item?.id}`}>
							<LazyLoaderWraper
								slot="media"
								src={item.profile_image ? item.profile_image : user}
								height={24}
								width={24}
								alt=""
							/>
							<span
								onClick={() => handleItemClickRemove(item)}
								className=" cursor-pointer  relative bottom-[-8px] ml-2 mr-[-7px]">
								<MaterialIcon
									size={20}
									icon="close"
									weight={300}
									fill
									color="--f7-heading-color"
								/>
							</span>
						</Chip>
					))}
				</div>
				{isLoading && (
					<div className="flex flex-col justify-center items-center h-4/5">
						<Preloader color="#0e99fe" />
					</div>
				)}
				{memberlist}
				{!isLoading && filteredContacts?.length > 0 ? (
					<List
						ref={contactsList}
						mediaList
						strongIos
						className="search-list contact-list2 searchbar-found">
						{filteredContacts?.map((contact, index) => (
							<ListItem
								key={index}
								checkbox
								checked={selectedItems?.find((item) => item.id === contact.id)}
								hideLastSeen
								onChange={() => handleItemClick(contact)}
								title={contact?.name || contact?.NAME || `User-${contact?.id}`}
								subtitle={contact.bio}
								className={`cursor-pointer ${
									contact.bio ? "" : "subtitle-center"
								}`}>
								<LazyLoaderWraper
									slot="media"
									src={contact.profile_image ? contact.profile_image : user}
									height={55}
									width={55}
									alt=""
									className="rounded-full align-bottom"
									wrapperclassname="rounded-full align-bottom"
								/>
							</ListItem>
						))}
					</List>
				) : (
					<List
						strongIos
						outlineIos
						dividersIos
						className="searchbar-not-found">
						<ListItem title="No contacts found" />
					</List>
				)}
				<List
					strongIos
					outlineIos
					dividersIos
					className="searchbar-not-found contacts-not-found2">
					<ListItem title="No contact found" />
				</List>
			</div>
			<Button
				disabled={selectedItems.length < 1}
				onClick={AddParticipants}
				className="fixed bottom-[20px] right-[30px] rounded-full flex
             items-center justify-center active-zoom z-10 w-[60px] h-[60px] bg-[--f7-md-primary] cursor-pointer">
				<MaterialIcon size={48} icon="done" fill color="white" />
			</Button>
		</Tab>
	);
};

export default AddMoreParticipants;
